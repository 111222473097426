import React, { useState, useEffect, useCallback } from "react";

import axios from 'axios';

import './css/index.css'
import Story from "../Story";

import { AiOutlineCloseSquare } from 'react-icons/ai';

import { Parallax } from 'react-parallax';

import forest from '../History/img/forest.webp'
import maria from '../History/img/Maria1.webp'
import wreckage from '../History/img/wreckage.webp'
import openLetter from '../History/img/openLetter.jpg'

export default function History() {
    const [background, setBackground] = useState(true);
    const [disastor, setDisastor] = useState(false);
    const [funding, setFunding] = useState(false);


	return (
        <>
        <Parallax bgImage={forest} blur={{ min: -4, max: 4 }} strength={-150}>
            <header className='history-header'>
                    <div className='history-header-content'>
                            <h1>OUR <br></br> HISTORY</h1>
                    </div>
            </header>
        </Parallax>
        
            <div className="history-content">

                {/* Depending on which button is active change the view  */}
                <div className="history-buttons">
                    <button className={background ? 'button-active' : 'button-inactive'} onClick={() => {
                    setBackground(true);
                    setDisastor(false);
                    setFunding(false);
                    }}>Background</button>
               
                    <button className={disastor ? 'button-active' : 'button-inactive'} onClick={() => {
                    setBackground(false);
                    setDisastor(true);
                    setFunding(false);
                    }}>Natural Disasters</button>
              
                    <button className={funding ? 'button-active' : 'button-inactive'} onClick={() => {
                    setBackground(false);
                    setDisastor(false);
                    setFunding(true);
                    }}>Funding</button>
                </div>

                <div className={background ? 'background-container' : 'hide'}>
                    <h2>Background</h2>
                    <p className="has-dropcap">In 1979, Hurricane David had devastated our island of Dominica. Dominicans in
Canada were concerned about the welfare of their families. A group of
Dominicans in Hamilton, Ontario, Canada decided to collaborate to help their
fellow Dominicans. They sought donations of non-perishable food and clothing
which were collected and sent to the Red Cross in Toronto to be shipped to
Dominica. A bank account was established in order to deposit monetary
donations that we received.
As the work involved required more organization, this group decided to form an
association – hence The Commonwealth of Dominica Association was formed.
<br></br>
<br></br>
We assumed responsibilities as follows: Denrick Musgrave, President; Francis
Grell, Vice President (deceased); Victoria Thomas, Secretary (deceased); and
Denia Dublin Henry as Treasurer. Mr. Franklin John Baptiste served as advisor
and liaison officer between the Toronto Association and the Hamilton
Association. Denrick served as president for seven years, while Victoria and
Denia took turns as Treasurer and Secretary.
<br></br>
<br></br>
CODAH has been a vibrant organization in the Hamilton community and has
collaborated in fundraising efforts with the Toronto Association for many years.
From the proceeds of our annual fundraising events, we have contributed to
scholarships for youth locally and in Dominica, assisted the elderly in Dominica
by sending barrels of essential items and funds to Senior Citizen Homes; youth-
at-risk organizations, and other individuals in dire need of assistance.
<br></br>
<br></br>
We have
also contributed to other causes such as earthquakes and floods in Haiti, St.
Vincent, Grenada, and other disasters in Africa.
More recently, in 2018, we organized a massive fundraising effort to assist our
fellow Dominicans after the massive devastation to the island by Hurricane
Maria.
<br></br>
<br></br>
We are grateful to the many volunteers and donors who continue to contribute to
those causes through their participation at our fundraising events and from their
monetary donations.
<br></br>
<br></br>
Thank you!.</p>     
                </div>
                <div className={disastor ? 'disastor-container' : 'hide'}>
                    <h2>Natural Disasters</h2>
                    <p className="has-dropcap">In 1979, Hurricane David had devastated our island of Dominica. Dominicans in Canada were concerned about the welfare of their families. A group of Dominicans in Hamilton, Ontario, Canada decided to take action to help their fellow Dominicans. They sought donations of non-perishable food and clothing which were collected and sent to the Red Cross in Toronto to be shipped to Dominica. A bank account was opened in order to deposit monetary donations we received. This group decided to form an association – hence The Commonwealth of Dominica Association was formed. We assumed responsibilities as follows: Denrick Musgrave, President, Francis Grell, Vice President, Victoria Thomas, Secretary, and Denia Dublin Henry as Treasurer. Mr. John Baptist served as advisor and liaison officer between the Toronto Association and Hamilton. Denrick served as president for seven years, while Victoria and Denia took turns as Treasurer and Secretary.</p>     
                    <div className="disastor-img-container">
                    <img src={maria}></img>

                    <img src={openLetter}></img>
                    </div>
                </div>


                <div className={funding ? 'funding-container' : 'hide'}>
                    <h2>Funding</h2>
                    {/* <h2>Regarding our fundraising efforts, CODAH has donated almost $98,000.00 throughout our 40 years of existence.</h2> */}
                    <div className="stories">
                        {/* creates stories dynamically */}
                       {/* <Story /> */}
                       <h1>2019</h1>
                       <div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                          
                            <div className="story-body">
                                <h4>{"Convent High School, Roseau, Dominica"}</h4>
                                <p>{'Donation of $500.00 CDN to help renovate the school’s roof.'}</p>
                            </div>   
                        </div>

                        <div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                            <div className="story-body">
                                <h4>{'Mahaut Health Centre, Dominica'}</h4>
                                <p>{'Donation of $500.00 CDN.'}</p>
                            </div>   
                        </div>

                        <div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                            <div className="story-body">
                                <h4>{'Mero Beach Enhancement Committee, Mero, Dominica'}</h4>
                                <p>{'Donation of $500.00 CDN.'}</p>
                            </div>   
                        </div>

                        <div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                            <div className="story-body">
                                <h4>{'Dominica Association of Peoples with Disabilities'}</h4>
                                <p>{'Donation of a wheelchair'}</p>
                            </div>   
                        </div>

                        <div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                            <div className="story-body">
                                <h4>{'Bagatelle/Fond St. Jean School.'}</h4>
                                <p>{'Donation of $500 CDN to ACCPI towards scholarship for a youth, Hamilton, Ontario'}</p>
                            </div>   
                        </div>



                        <h1>2018</h1>


                        <div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                            <div className="story-body">
                                <h4>{'Dominican student from the Kalinago Territory in Dominica'}</h4>
                                <p>{'$500 Scholarship award'}</p>
                            </div>   
                        </div>
                        <div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                            <div className="story-body">
                                <h4>{'Dominican Parentage'}</h4>
                                <p>{'Scholarships totaling $700 to two students of Dominican parentage.'}</p>
                            </div>   
                        </div>
                        <div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                            <div className="story-body">
                                <h4>{'Bagatelle/Fond St. Jean School'}</h4>
                                <p>{'Donation of $1,000.00 towards purchase of laptops and school supplies.'}</p>
                            </div>   
                        </div>
                        <div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                            <div className="story-body">
                                <h4>{'Mahaut Girl Guides'}</h4>
                                <p>{'Shipped two barrels containing paint, medical supplies and other goods to Mahaut Girl Guides.'}</p>
                            </div>   
                        </div>


                        <h1>2017</h1>

                        <div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                            <div className="story-body">
                                <h4>{'Hurricane Maria Relief'}</h4>
                                <p>{'Collected donations totaling $4880.00 for Hurricane Maria Relief. Amount submitted to CDOA, to be added to the general Maria Relief donations for 2018.'}</p>
                            </div>   
                        </div>
                        <div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                            <div className="story-body">
                                <h4>{'Hurricane Maria Relief'}</h4>
                                <p>{'From the total proceeds of $5,197.00, from our Fundraising efforts - Dominican Marketplace and Dance in November, 2017 in aid of Hurricane Maria Relief - we purchased essential goods including medical supplies which were shipped them in barrels to Grandbay, Fond St. Jean, Point Mitchel, Wesley, Mahaut/Campbell/Warner and Marigot Village Councils. We will be making further donations to other organizations in Dominica after reviewing relevant needs.'}</p>
                            </div>   
                        </div><div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                            <div className="story-body">
                                <h4>{'Hurricane Maria Relief'}</h4>
                                <p>{'From donations collected from various businesses and individuals in and around our City, we were able to fill three truckloads of non-perishable goods and medical supplies for Dominica, including the purchase of tarpaulins, which were delivered to CDOA for shipment.'}</p>
                            </div>   
                        </div>

                        <h1>2016</h1>

                        <div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                            <div className="story-body">
                                <h4>{'CANDO'}</h4>
                                <p>{'We donated $200.00 donation to CANDO (a group of about 7 fellow Dominicans in the Toronto/Brampton, Ontario vicinity, who award scholarships to high school students in Dominica).'}</p>
                            </div>   
                        </div>

                        <h1>2015</h1>

                        <div className='story'>
                            {/* <header>
                                <img src={wreckage} />
                            </header> */}
                            <div className="story-body">
                                <h4>{'CDOA (Toronto)'}</h4>
                                <p>{'Donated $6,300.00 to CDOA (Toronto) which was the proceeds towards Tropical Storm Ericka Relief efforts.'}</p>
                            </div>   
                        </div>

                        <h1>2013</h1>

                            <div className='story'>
                                {/* <header>
                                    <img src={wreckage} />
                                </header> */}
                                <div className="story-body">
                                    <h4>{'Supporting The GTA'}</h4>
                                    <p>{'Donated to Foodshare program in Hamilton, ACCPI Scholarship Awards, Good Shepard Centre and CODAH Youth Scholarship.'}</p>
                                </div>   
                            </div>

                            <h1>2012</h1>

                            <div className='story'>
                                {/* <header>
                                    <img src={wreckage} />
                                </header> */}
                                <div className="story-body">
                                    <h4>{'Mahaut'}</h4>
                                    <p>{'Donations to Senior Citizens home, and Youth Resource Centre in Mahaut.'}</p>
                                </div>   
                            </div>

                            <h1>2011</h1>

                            <div className='story'>
                                {/* <header>
                                    <img src={wreckage} />
                                </header> */}
                                <div className="story-body">
                                    <h4>{'Roseau'}</h4>
                                    <p>{'Abused Women’s Organization in Roseau.'}</p>
                                </div>   
                            </div>

                            <h1>2010</h1>

                            <div className='story'>
                                {/* <header>
                                    <img src={wreckage} />
                                </header> */}
                                <div className="story-body">
                                    <h4>{'Council of Caribbean Association (Rebuilding Haiti)'}</h4>
                                    <p>{'Donations were given to the Council of Caribbean Association (Rebuilding Haiti) fundraising drive.'}</p>
                                </div>   
                            </div>

                            <h1>2008</h1>

                            <div className='story'>
                                {/* <header>
                                    <img src={wreckage} />
                                </header> */}
                                <div className="story-body">
                                    <h4>{'Supporting the GTA'}</h4>
                                    <p>{'The Toronto Dominica Soccer team received funds to assist with travel for a tournament in Dominica; Approximately $5,000.00 to individuals seeking financial assistance: We donated to the Salvation Army, Neighbour-to-Neighbour organization, Good Shepherd Centre, Living Rock Centre, Hamilton Food Share, Martha’s House.'}</p>
                                </div>   
                            </div>

                            <h1>2007</h1>

                                <div className='story'>
                                    {/* <header>
                                        <img src={wreckage} />
                                    </header> */}
                                    <div className="story-body">
                                        <h4>{"Avery's Journey"}</h4>
                                        <p>{'Avery’s Journey – Retinoblastoma Cancer Research and to Heart and Stroke Foundation and Diabetes organizations.'}</p>
                                    </div>   
                                </div>

                                <div className='story'>
                                    {/* <header>
                                        <img src={wreckage} />
                                    </header> */}
                                    <div className="story-body">
                                        <h4>{"ACCPI Scholarship"}</h4>
                                        <p>{'Annual scholarship donations to ACCPI scholarship award – 12 of our Dominican youth have received such monetary awards.'}</p>
                                    </div>   
                                </div>


                                <h1>2007</h1>

                                <div className='story'>
                                    {/* <header>
                                        <img src={wreckage} />
                                    </header> */}
                                    <div className="story-body">
                                        <h4>{"Avery's Journey"}</h4>
                                        <p>{'Retinoblastoma Cancer Research and to Heart and Stroke Foundation and Diabetes organizations.'}</p>
                                    </div>   
                                </div>


                                
                                <h1>2005</h1>

                                <div className='story'>
                                    {/* <header>
                                        <img src={wreckage} />
                                    </header> */}
                                    <div className="story-body">
                                        <h4>{"Roseau, Princess Margaret Hospital and Hamilton Youth Steel Orchestra"}</h4>
                                        <p>{'Donations to Cathedral Renovation fund in Roseau, Princess Margaret Hospital and the Hamilton Youth Steel Orchestra in Hamilton, Ontario.'}</p>
                                    </div>   
                                </div>

                                <h1>2004</h1>

                                <div className='story'>
                                    {/* <header>
                                        <img src={wreckage} />
                                    </header> */}
                                    <div className="story-body">
                                        <h4>{"Bagatelle School"}</h4>
                                        <p>{'Donated audio Equipment and musical instruments '}</p>
                                    </div>   
                                </div>

                                <div className='story'>
                                    {/* <header>
                                        <img src={wreckage} />
                                    </header> */}
                                    <div className="story-body">
                                        <h4>{"Marigot Senior Citizens Home"}</h4>
                                        <p>{'Donated hospital beds'}</p>
                                    </div>   
                                </div>

                                <div className='story'>
                                    {/* <header>
                                        <img src={wreckage} />
                                    </header> */}
                                    <div className="story-body">
                                        <h4>{"Mahaut Day Care Centre"}</h4>
                                        <p>{'Donated toys and books'}</p>
                                    </div>   
                                </div>

                                <div className='story'>
                                    {/* <header>
                                        <img src={wreckage} />
                                    </header> */}
                                    <div className="story-body">
                                        <h4>{"Natural Disaster Support"}</h4>
                                        <p>{' We donated to the Grenada Relief Hurricane fund and to the Guyana flood relief, as well as to the Jamaica Hurricane fund, and $1,000.00 to individuals who required various forms of assistance.'}</p>
                                    </div>   
                                </div>


                                <h1>2003</h1>
                                <div className='story'>
                                    {/* <header>
                                        <img src={wreckage} />
                                    </header> */}
                                    <div className="story-body">
                                        <h4>{"Girl Guides Association in Dominica"}</h4>
                                        <p>{'Donated funds to help purchase 15 uniforms for girl guides.'}</p>
                                    </div>   
                                </div>

                                
                                <h1>2000</h1>
                                <div className='story'>
                                    {/* <header>
                                        <img src={wreckage} />
                                    </header> */}
                                    <div className="story-body">
                                        <h4>{"Alpha Centre, Roseau, Dominica"}</h4>
                                        <p>{'Helped build Wheel-chair accessible toilets for physically-cahllenged children.'}</p>
                                    </div>   
                                </div>


                               
                        
                    </div>
               
                </div>

            </div>
        </>
          
       

       
  );
}
