import { useLocation, useNavigate } from "react-router-dom";
import { useState } from 'react';
import './css/index.css'


import logo from '../../componenets/Header/img/codah-logo.webp'

function Header() {
    const [ navbar, setNavbar ] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();


    // Get Todays Date
    const nameOfMonthUS = new Date().toLocaleString(
        'en-US', {month: 'long'}
      );
    const todaysDate = new Date().getDate()



      // When the window passes a certain point on the viewport the state of the navbar and its style is changed
    const changeBackground = () => {
          if (window.scrollY >= 80) {
              setNavbar(true);
          } else {
              setNavbar(false);
          }
    };
   
    window.addEventListener('scroll', changeBackground);

  return (
    <div className={navbar ? "nav-container-scrolled" : "nav-container"}>
        <div className={navbar ? 'hide-main-nav' : 'main-nav'}>
                <div className="nav-logo">
                    <a href="/"><img src={logo}></img></a>
                    
                    <div className={"today-container"}>
                        <a href="events">
                            <div className="today">
                            <h3 className="date">{todaysDate}</h3>
                            <h2 className="date">{nameOfMonthUS.slice(0, 3)}</h2>
                        </div>
                        </a>
                        
                        <a href="events"><h6 className="event-calendar">EVENT CALENDAR</h6></a>
                        
                    </div>
                </div>
            </div>
        <nav className={navbar ? "nav-scrolled" : "nav"}>
            <ul className={navbar ? "menu-list-scrolled" : "menu-list"}>
                <li><a className={location.pathname === '/' ? 'list-item-selected' : ''} href='/'>HOME</a></li>
                <li><a className={location.pathname === '/history' ? 'list-item-selected' : ''} href='/history'>HISTORY</a></li>
                <li><a className={location.pathname === '/events' ? 'list-item-selected' : ''} href='/events'>EVENTS CALENDAR</a></li> 
                <li><a className={location.pathname === '/gallery' ? 'list-item-selected' : ''} href='/gallery'>GALLERY</a></li>
                <li><a className={location.pathname === '/donate' ? 'list-item-selected' : ''} href='/donate'>DONATE</a></li>
                <li><a className={location.pathname === '/contact' ? 'list-item-selected' : ''} href='/contact'>CONTACT</a></li>
            </ul>
        </nav>
    </div>
    
  );
}

export default Header;

