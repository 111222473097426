
import './css/index.css';
import { Link } from "react-router-dom";
import { Parallax } from "react-parallax";


export default function NotFound() {
	return (
            <Parallax  blur={{ min: -4, max: 4 }} strength={-150}  className={'missing-header'}>
                <h1>OOPS! </h1>
                <h2>We couldn't find the page you're looking for.</h2>
                <Link to='/'>
                    <button className='go-home'>Go to home page</button>
                </Link>
            </Parallax >
    );
}