import './css/index.css'

import React, { useRef, useState } from 'react';
import  { Link } from 'react-router-dom'

import { AiFillPhone } from 'react-icons/ai';
import { AiFillMail } from 'react-icons/ai';
import { FaLocationArrow } from 'react-icons/fa'

import coast from './img/coast.webp'

import { Parallax } from 'react-parallax';

import emailjs from '@emailjs/browser';

import ReCAPTCHA from "react-google-recaptcha";

import axios from 'axios'



export default function Contact() {
    const [reachOut, setReachOut] = useState(true);
    const [ourInformation, setOurInformation] = useState(false);

    const [volunteer, setVolunteer] = useState(false);
    const [proposal, setProposal] = useState(false);
    const [other, setOther] = useState(true);
    const [verified, setVerified] = useState(false);

    const [formData, setFormData] = useState({
        full_name: "",
        email: "",
        phone: "",
        message: "",
        subject: "Volunteer"
    });

    const recaptchaRef = React.createRef();
    const form = useRef();

    // inserts form data to database

    // const handleSubmit = () => {

    //         if (formData.full_name === "" || formData.email === "" || formData.phone === "" || formData.message === "" || formData.subject === "") {
    //             alert('Please fill out the form completely before submitting your message!')
    //         }

    //         if (formData.full_name !== "" && formData.email !== "" && formData.phone !== "" && formData.message !== "" && formData.subject !== "") {
    //             axios.post('http://localhost:5000/email/new', formData).then((res) => {
                
    //         }).catch((err) => console.log(err))
    //         }
            
    // }

    function onChange(value) {
        setVerified(true);
        if (value === null) {
            return setVerified(false);
        }

     
    }
    
    const sendEmail = (e) => {

        // e.preventDefault();

        if (formData.full_name === "" || formData.email === "" || formData.phone === "" || formData.message === "" || formData.subject === "") {
            setVerified(false);
            return alert('Please fill out the form completely before submitting your message!');
        }

            emailjs.sendForm('service_xyw8cxc', "template_ciarfku", form.current, 'pT-07eI5_FKNUBpQh')
                // .then((result) => {
                //     console.log(result.text);
                // }).catch((err) => {
                //     console.log(err)
                // })




            
            // TEST EMAIL

            // emailjs.sendForm('service_k17t3nq', "template_jem9a0o", form.current, 'oUo_xVJa2vOwPnO6a')
            //     .then((result) => {
            //         console.log(result.text);
            //     }).catch((err) => {
            //         console.log(err)
            //     });
    
                // handleSubmit()

                alert('Thank you! Our team will get back to you shortly.');
            setFormData({
                full_name: "",
                email: "",
                phone: "",
                message: "",
                subject: "Volunteer"
            });
           form.current.reset()

        
        
    };

	return (
    <>
    <Parallax bgImage={coast} blur={{ min: -4, max: 4 }} strength={-150}>
        <header className='contact-header'>
                <div className='contact-header-content'>
                    <h1>CONTACT<br></br>US</h1>
                </div>  
        </header>
    </Parallax>
   

      <div className='contact-container'>

      <div className="contact-buttons">
            <button className={reachOut ? 'contact-button-active' : 'contact-button-inactive'} onClick={() => {
              setReachOut(true);
              setOurInformation(false);
              }}>Reach out
              </button>

              <button className={ourInformation ? 'contact-button-active' : 'contact-button-inactive'} onClick={() => {
               setReachOut(false);
               setOurInformation(true);
              }}>Our Information      
            </button>           
      </div>
              <div className='our-information-container'>
                <div className={ourInformation ? 'our-information' : 'hide-our-information'}>
                        <h1>Contact Information</h1>
                        <span>Feel free to contact us and our team will get back to you within 24 hours.</span>
                        <div className='our-information-content'>
                            <h4><AiFillPhone className='information-icon' /> 905-575-4042</h4>
                            <h4><AiFillMail className='information-icon' /> codahamilton@gmail.com</h4>

                        </div>                                         
                    </div>
              </div>
                
                  <form id='form' ref={form} className={reachOut ? 'contact-form' : 'hide-contact-form'}>
                  
                        <div className='contact-form-inputs'>
                        <h3>Basic Details</h3>
                            <div className='contact-form-input-group'>   
                                    <div className='group-spacing'>
                                        <div>
                                            <label htmlFor='fullName'>Full Name</label>
                                            <input type='text' name='fullName' 
                                            onChange={(event) =>{
                                             setFormData({ ...formData, full_name: event.target.value })
                                            }}></input>
                                        </div> 

                                        <div>
                                            <label htmlFor='email'>Email</label>
                                            <input type='email' name='email' onChange={(event) =>{
                                             setFormData({ ...formData, email: event.target.value })
                                            }}></input>
                                        </div>
                                        

                                        <div>
                                            <label htmlFor='phone'>Phone</label>
                                            <input name='phone' onChange={(event) =>{
                                             setFormData({ ...formData, phone: event.target.value })
                                            }}></input>
                                        </div>
                                        <div className={other ? 'subject2' : 'hide-subject2'}>
                                <label htmlFor='subject'>Subject (e.g. Volunteer or Proposal)</label>
                                <input name='subject' onChange={(event) =>{
                                             setFormData({ ...formData, subject: event.target.value })
                                            }}></input>
                            </div>
                                    </div>
                                       
                                        <div>
                                            <label htmlFor='message'>Message</label>
                                            <textarea name='message' className='message' onChange={(event) =>{
                                             setFormData({ ...formData, message: event.target.value })
                                            }}></textarea>
                                        </div>  

                                        
                            
                            </div>
                            
                            
                            
                            <div className='subject-section'>     
                            {/* <h3>Subject</h3> */}
                            {/* <div className='subject-buttons'>
                            <button type='button' className={volunteer ? 'button-active' : 'subject-button-inactive'} onClick={() => {
                                setVolunteer(true);
                                setProposal(false);
                                setOther(false);
                                setFormData({ ...formData, subject: 'Volunteer'})
                                }}>Volunteer
                            
                            </button>
                                <button type='button' className={proposal ? 'button-active' : 'subject-button-inactive'} onClick={() => {
                                     setVolunteer(false);
                                     setProposal(true);
                                     setOther(false);
                                     setFormData({ ...formData, subject: 'Proposal'})
                            
                                }}>Proposal</button>
                                <button type='button' className={other ? 'button-active' : 'subject-button-inactive'} 
                                onClick={() => {
                                     setVolunteer(false);
                                     setProposal(false);
                                     setOther(true);
                                     setFormData({ ...formData, subject: ''})
                                }}>Other</button>
                            </div> */}
                              
                            </div>

                        
                            <button disabled={verified ? false : true} type='submit' className={verified ? 'contact-submit' : 'contact-disabled'} onClick={sendEmail}>Send Message</button>
                   

                            <ReCAPTCHA
                                ref={recaptchaRef}
                                className='recaptcha'
                                sitekey="6LfHFREjAAAAACkFzOPS3MaipYiapel_6FqRvYaA"
                                onChange={onChange}
                            />
                           
                        </div>  
                          
                        
                  </form>
                  
          </div>
    </>
  );
}
