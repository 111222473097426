// Theres a space at the end of each album string so that theres a space beteween the word and the icon in the dropdown menu

const IMAGES = [
    {
      src: 'https://live.staticflickr.com/65535/52153604843_96ffd0004e_z.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52153604843_96ffd0004e_z.jpg',
      thumbnailWidth: 520,
      thumbnailHeight: 212,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52499508412_9bbfdc1aee_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52499508412_9bbfdc1aee_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500550298_d82b31ce47_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500550298_d82b31ce47_b.jpg',
      thumbnailWidth: 150,
      thumbnailHeight: 100,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500550383_faa099682a_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500550383_faa099682a_b.jpg',
      thumbnailWidth: 700,
      thumbnailHeight: 350,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500550293_32435f727a_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500550293_32435f727a_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500473225_604bdab99b_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500473225_604bdab99b_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500550268_6ded4d9574_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500550268_6ded4d9574_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52153604833_402f1346de_z.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52153604833_402f1346de_z.jpg',
      thumbnailWidth: 350,
      thumbnailHeight: 212,
      tags: [
        { value: "People", title: "People" }
      ],
      caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500550233_c5255a5427_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500550233_c5255a5427_b.jpg',
      thumbnailWidth: 250,
      thumbnailHeight: 100,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500550258_62347101e2_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500550258_62347101e2_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52499994411_80352ab051_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52499994411_80352ab051_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500550218_1b0632ea3d_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500550218_1b0632ea3d_b.jpg',
      thumbnailWidth: 250,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500280229_a441dcda4a_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500280229_a441dcda4a_b.jpg',
      thumbnailWidth: 350,
      thumbnailHeight: 150,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500550193_2baa7a7054_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500550193_2baa7a7054_b.jpg',
    thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52499508312_326386e10e_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52499508312_326386e10e_b.jpg',
      thumbnailWidth: 150,
      thumbnailHeight: 100,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500550168_bb5f5d4331_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500550168_bb5f5d4331_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52153839119_ca02259b55_z.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52153839119_ca02259b55_z.jpg',
      thumbnailWidth: 420,
      thumbnailHeight: 212,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500473105_f4721daf45_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500473105_f4721daf45_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52499508257_b59fe69047_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52499508257_b59fe69047_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52499508272_db2e2aa202_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52499508272_db2e2aa202_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500473040_c94579947a_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500473040_c94579947a_b.jpg',
      thumbnailWidth: 400,
      thumbnailHeight: 200,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500473050_1b2a018b60_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500473050_1b2a018b60_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500550093_7152812696_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500550093_7152812696_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52499994521_e1c4430cb2_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52499994521_e1c4430cb2_b.jpg',
      thumbnailWidth: 500,
      thumbnailHeight: 250,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500280174_d4f3759c48_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500280174_d4f3759c48_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500473305_0a550f167f_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500473305_0a550f167f_b.jpg',
      thumbnailWidth: 250,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52499508412_9bbfdc1aee_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52499508412_9bbfdc1aee_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52499508382_1551e19d1f_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52499508382_1551e19d1f_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52499994496_33c2a13d17_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52499994496_33c2a13d17_b.jpg',
      thumbnailWidth: 150,
      thumbnailHeight: 100,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500473270_60b94e0b65_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500473270_60b94e0b65_b.jpg',
      thumbnailWidth: 150,
      thumbnailHeight: 100,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52500550313_0ef2eb4db4_b.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52500550313_0ef2eb4db4_b.jpg',
      thumbnailWidth: 300,
      thumbnailHeight: 250,
      album: 'Independence Celebration 2019 '
    },
    {
      src: 'https://live.staticflickr.com/65535/52152591187_789d41bf4b_z.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52152591187_789d41bf4b_z.jpg',
      thumbnailWidth: 520,
      thumbnailHeight: 212,
      tags: [
        { value: "People", title: "People" }
      ],
      caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52225980001_38af75e767_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52225980001_38af75e767_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52224973562_c483b98c4c_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52224973562_c483b98c4c_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52224973532_8a223181b1_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52224973532_8a223181b1_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52225979931_5284e423da_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52225979931_5284e423da_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52225979921_106c0a6570_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52225979921_106c0a6570_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52225990833_956aa475c1_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52225990833_956aa475c1_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52226464370_74af45953c_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52226464370_74af45953c_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52225979841_671fa27e52_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52225979841_671fa27e52_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52225990723_e371347bd3_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52225990723_e371347bd3_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52224973317_423973bea2_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52224973317_423973bea2_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52226250109_74e8e69e17_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52226250109_74e8e69e17_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52225979741_68a6173560_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52225979741_68a6173560_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52224973242_79687c5247_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52224973242_79687c5247_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52225990628_3d6ce61a71_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52225990628_3d6ce61a71_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52224973222_2167241308_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52224973222_2167241308_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52226464080_93b04abc53_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52226464080_93b04abc53_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52226464055_eb3c59a988_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52226464055_eb3c59a988_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52226464030_f36205b455_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52226464030_f36205b455_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52225990473_ede2d339de_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52225990473_ede2d339de_b.jpg",
      thumbnailWidth: 380,
      thumbnailHeight: 400,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Celebration 2019 '
    },
    {
      src: "https://live.staticflickr.com/65535/52226006566_6a1648a680_m.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52226006566_6a1648a680_m.jpg",
        thumbnailWidth: 700,
        thumbnailHeight: 450,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Tourism '
    },
    {
      src: 'https://live.staticflickr.com/65535/52154094385_a6d3a81c67_z.jpg',
      thumbnail:
      'https://live.staticflickr.com/65535/52154094385_a6d3a81c67_z.jpg',
      thumbnailWidth: 350,
      thumbnailHeight: 174,
      album: 'Tourism '
    },
    {
      src: "https://live.staticflickr.com/65535/52225000722_64e62e5f30_m.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52225000722_64e62e5f30_m.jpg",
        thumbnailWidth: 650,
        thumbnailHeight: 450,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Tourism '
    },
    {
      src: "https://live.staticflickr.com/65535/52226491790_90650205e3_m.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52226491790_90650205e3_m.jpg",
      thumbnailWidth: 800,
      thumbnailHeight: 450,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Tourism '
    },
    {
      src: "https://live.staticflickr.com/65535/52500214394_6b4d76cd80_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52500214394_6b4d76cd80_o.jpg",
      thumbnailWidth: 1000,
      thumbnailHeight: 450,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Natural Disasters '
    },
    {
      src: "https://live.staticflickr.com/65535/52500408260_298d65622e_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52500408260_298d65622e_o.jpg",
      thumbnailWidth: 1000,
      thumbnailHeight: 450,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Natural Disasters '
    },
    {
      src: "https://live.staticflickr.com/65535/52500484488_705beb12b4_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52500484488_705beb12b4_o.jpg",
      thumbnailWidth: 1000,
      thumbnailHeight: 450,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Natural Disasters '
    },
    {
      src: "https://live.staticflickr.com/65535/52500408285_a24acefe1a_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52500408285_a24acefe1a_o.jpg",
      thumbnailWidth: 1000,
      thumbnailHeight: 450,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Natural Disasters '
    },
    {
      src: "https://live.staticflickr.com/65535/52504065708_e91f30a88e_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504065708_e91f30a88e_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Market Place 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504065718_4912d876a7_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504065718_4912d876a7_b.jpg",
      thumbnailWidth: 500,
      thumbnailHeight: 250,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Market Place 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503989240_d6bf712806_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503989240_d6bf712806_b.jpg",
        thumbnailWidth: 400,
        thumbnailHeight: 250,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Market Place 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503515841_6da5e51632_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503515841_6da5e51632_b.jpg",
        thumbnailWidth: 400,
        thumbnailHeight: 250,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Market Place 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503989140_6e6613fc69_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503989140_6e6613fc69_b.jpg",
        thumbnailWidth: 400,
        thumbnailHeight: 250,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Market Place 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503515801_312aa44720_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503515801_312aa44720_b.jpg",
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Market Place 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504065723_a6ba1fde1a_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504065723_a6ba1fde1a_b.jpg",
        thumbnailWidth: 400,
        thumbnailHeight: 250,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Market Place 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503785574_401b6571f6_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503785574_401b6571f6_b.jpg",
      thumbnailWidth: 300,
      thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Market Place 2018 '
    },
    
    {
      src: "https://live.staticflickr.com/65535/52504065738_50ed1c025a_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504065738_50ed1c025a_b.jpg",
        thumbnailWidth: 400,
        thumbnailHeight: 250,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Market Place 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503989165_f6432b621b_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503989165_f6432b621b_b.jpg",
        thumbnailWidth: 400,
        thumbnailHeight: 250,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Market Place 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504065798_2533962682_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504065798_2533962682_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Market Place 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503515736_b316873093_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503515736_b316873093_b.jpg",
        thumbnailWidth: 400,
        thumbnailHeight: 250,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Market Place 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504065768_0dd13f47ba_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504065768_0dd13f47ba_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Market Place 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503070577_c79800ba63_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503070577_c79800ba63_b.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Christmas 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503836169_54fe1c4827_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503836169_54fe1c4827_b.jpg",
        thumbnailWidth: 600,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Christmas 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503070547_3a8b8df11c_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503070547_3a8b8df11c_b.jpg",
        thumbnailWidth: 700,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Christmas 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503836289_f6561a1c92_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503836289_f6561a1c92_b.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 500,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Christmas 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503566161_18a97e7835_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503566161_18a97e7835_b.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 250,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Christmas 2018 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503080202_412220090b_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503080202_412220090b_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Canada Day 2017 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504126263_ec59f6e513_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504126263_ec59f6e513_b.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 250,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Canada Day 2017 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504126238_e01dc2a0e0_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504126238_e01dc2a0e0_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Canada Day 2017 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503845844_f0ef0b0db6_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503845844_f0ef0b0db6_b.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 250,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Canada Day 2017 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503080182_a8e4ee404f_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503080182_a8e4ee404f_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Canada Day 2017 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503575196_ab5fc68775_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503575196_ab5fc68775_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Hurricane Relief 2017 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504049410_15cfa42f3f_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504049410_15cfa42f3f_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Hurricane Relief 2017 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503575141_e2cc38e68d_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503575141_e2cc38e68d_b.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Hurricane Relief 2017 '
    },

    {
      src: "https://live.staticflickr.com/65535/52504049425_df0caf28ed_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504049425_df0caf28ed_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Hurricane Relief 2017 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504049510_64fcf12d73_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504049510_64fcf12d73_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Hurricane Relief 2017 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503861454_9724b40792_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503861454_9724b40792_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Belle Marche/Confederation Park picnic '
    },
    {
      src: "https://live.staticflickr.com/65535/52504065025_7bbb3db4b6_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504065025_7bbb3db4b6_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Belle Marche/Confederation Park picnic '
    },
    {
      src: "https://live.staticflickr.com/65535/52503095432_7c75f6be7e_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503095432_7c75f6be7e_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Belle Marche/Confederation Park picnic '
    },
    {
      src: "https://live.staticflickr.com/65535/52504142223_46a4ae551d_b.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504142223_46a4ae551d_b.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Belle Marche/Confederation Park picnic '
    },
    {
      src: "https://live.staticflickr.com/65535/52503870509_a459eaefb0_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503870509_a459eaefb0_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Dance November 2013 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504074285_38fc29d13f_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504074285_38fc29d13f_o.jpg",
        thumbnailWidth: 200,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Dance November 2013 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503599496_94a82901b3_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503599496_94a82901b3_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Dance November 2013 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503104272_c8fa07c1e3_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503104272_c8fa07c1e3_o.jpg",
        thumbnailWidth: 300,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Dance November 2013 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503599506_d4041958cd_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503599506_d4041958cd_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Dance November 2013 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504151023_4a55aec098_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504151023_4a55aec098_o.jpg",
        thumbnailWidth: 400,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Dance November 2013 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503870529_697754a2b6_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503870529_697754a2b6_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Dance November 2013 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503870534_b1c04566b6_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503870534_b1c04566b6_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Dance November 2013 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503599536_e2c8ac2e34_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503599536_e2c8ac2e34_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Dance November 2013 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504151063_e73c62f848_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504151063_e73c62f848_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Dance November 2013 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504151068_0d7c052f55_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504151068_0d7c052f55_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 300,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Dance November 2013 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504151078_99a34d797c_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504151078_99a34d797c_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 500,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Independence Dance November 2013 '
    },
    {
      src: "https://live.staticflickr.com/65535/52503614711_39a59b4dab_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52503614711_39a59b4dab_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 500,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Mothers Day 2014 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504167148_0fedede997_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504167148_0fedede997_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 500,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Mothers Day 2014 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504167133_0ff48f91af_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504167133_0ff48f91af_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 500,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Mothers Day 2014 '
    },
    {
      src: "https://live.staticflickr.com/65535/52504094585_76c011c79f_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504094585_76c011c79f_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 500,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Miscellaneous '
    },
    {
      src: "https://live.staticflickr.com/65535/52504094595_01c9c248a8_o.jpg",
      thumbnail:
        "https://live.staticflickr.com/65535/52504094595_01c9c248a8_o.jpg",
        thumbnailWidth: 500,
        thumbnailHeight: 500,
      // tags: [
      //   { value: "Ocean", title: "Ocean" },
      // ],
      // caption: "Boats (Jeshu John - designerspics.com)",
      album: 'Miscellaneous '
    },





    
  
   
];

module.exports = IMAGES;