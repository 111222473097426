import { useCallback, useState, useEffect } from 'react';
import Calendar from 'react-awesome-calendar';
import './css/index.css'
import eventIMG from './img/wheel.jpg';
import { Parallax } from 'react-parallax';

import Event from '../Event/index'

import { useNavigate } from "react-router-dom";


const events = [
  {
  id: 1,
  color: '#3694DF',
  from: '2023-07-16T09:00:00+00:00',
  to: '2023-07-16T13:00:00+00:00',
  title: 'CODAH Picnic - 5050 Harrison Rd, Binbrook, ON L0R 1C0'
},{
  id: 2,
  color: '#3694DF',
  from: '2023-07-30T09:00:00+00:00',
  to: '2023-07-30T13:00:00+00:00',
  title: 'CODAH Picnic - 5050 Harrison Rd, Binbrook, ON L0R 1C0'
},
{
  id: 3,
  color: '#3694DF',
  from: '2023-08-20T09:00:00+00:00',
  to: '2023-08-20T13:00:00+00:00',
  title: 'CODAH Picnic - 5050 Harrison Rd, Binbrook, ON L0R 1C0'
},
{
  id: 4,
  color: '#3694DF',
  from: '2023-09-03T09:00:00+00:00',
  to: '2023-09-03T13:00:00+00:00',
  title: 'CODAH Picnic - 5050 Harrison Rd, Binbrook, ON L0R 1C0'
},
{
  id: 5,
  color: '#3694DF',
  from: '2023-10-04T09:00:00+00:00',
  to: '2023-10-04T13:00:00+00:00',
  title: 'Dominica Independence Celebration - Location TBD'
}
];


export default function Events() {

    let navigate = useNavigate();
    
    // const [events, setEvents] = useState([]);

    // const getEvents = useCallback(async() => {
    //     const data = await axios.get('http://localhost:5000/events').then((response) => {     
    //         setEvents(response.data);
    //     }).catch((err) => {
    //         console.log('ERR!', err)
    //     })
    // }, []);

    

    // useEffect(() => {
    //     getEvents()
    // },[])

	return (
        <> 
            <Parallax className='events-header' bgImage={eventIMG} blur={{ min: -4, max: 2 }} strength={-150}>
                {/* <header>
                    <div className='events-header-content'>
                        <h1>UPCOMING EVENTS<br></br> & CODAH UPDATES</h1>
                    </div>
                </header> */}
            </Parallax>

        <div className='events-container'>
            <div className='upcoming-events'>
                <div className='events'>
                   
                    <div className='calendar'>
                    <Calendar
                      events={events}
                    />
                    </div>

                    {/* creates mobile events dynamically */}
                    {/* <Event events={events} /> */}
                 
                </div>
            </div>
            <div className='volunteer-section'>
                <div className='become-a-volunteer'>
                    <h1>BECOME A VOLUNTEER</h1>
                    <h5>Join the CODAH family and we can create change together!</h5>
                    <button className='register-button' onClick={() => navigate('/contact')}>REGISTER AS A VOLUNTEER</button>
                </div>
                
            </div>
            
        </div>
        </>
  );
}
