import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './componenets/Header'
import MobileHeader from './componenets/MobileHeader';
import Home from './componenets/Home';
import NotFound from './componenets/NotFound/NotFound';

import History from './componenets/History';
import Events from './componenets/Events';
import GalleryGrid from './componenets/GalleryGrid';
import Donate from './componenets/Donate';
import Contact from './componenets/Contact';
import Footer from './componenets/Footer'

import {  BrowserRouter as Router,  Routes,  Route} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <MobileHeader />
        <Routes>
          <Route path='*' element={<NotFound />}/>
          <Route path='/' element={ <Home /> } />
          <Route path='/history' element={ <History /> } />
          <Route path='/events' element={ <Events/> } />
          <Route path='/gallery' element={ <GalleryGrid/> } />
          <Route path='/donate' element={ <Donate/> } />
          <Route path='/contact' element={ <Contact/> } />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
