import './css/index.css'

import { Dropdown } from 'react-bootstrap';

import { Parallax } from 'react-parallax';

import IMAGES from './images';
import Gallery from 'react-grid-gallery';

import beach from './img/beach.webp';

import { useCallback, useState, useEffect } from 'react';

import axios from 'axios';



export default function GalleryGrid() {
        // // Initial data pull
        // const [ galleryData, setGalleryData ] = useState([]);
        // List of albums in drop down
        // const [ albums, setAlbums ] = useState(['Independence Celebration 2019', 'Island Attractions']);
        // Current album selected
        const [ album, setAlbum ] = useState('Independence Celebration 2019 ');
        // Current Gallery photos being displayed
        const [ gallery, setGallery] = useState(IMAGES);

        
    //     // const getGallery = useCallback(async() => {
    //     //     const data = await axios.get(`http://localhost:5000/album/${album + 1}`).then((response) => {
    //     //        return setGallery(response.data); 
    //     //     }).catch((err) => {
    //     //         console.log('ERR!', err);
    //     //     })
    //     // }, [album])  
        
    const filterGallery = (album) => {
        const newIMAGES = [];
        IMAGES.filter((x) => {
            if ( album === x.album ) {
                newIMAGES.push(x);
            }
        });

        setGallery(newIMAGES)
    }


   
    // const getAlbums = useCallback(async() => {
    //     const data = await axios.get('http://localhost:5000/album').then((response) => {

    //         let tempArr = [];
            
    //         // Finds all albums
    //         response.data.map((image) => {
    //             let tempObj = {};
    //             tempObj.title = image.albumname;
    //             tempObj.id = image.albumid
    //             tempArr.push(tempObj)
    //         });
          
    //         // Only unique album objects pass
    //         const uniqueAlbums = new Set(tempArr.map(e => JSON.stringify(e)));

    //         const res = Array.from(uniqueAlbums).map(e => JSON.parse(e));
    //         setGalleryData(response.data);
    //         setGallery(response.data);
    //         setAlbums(res);
    //     })
    //     .catch((err) => {
    //         console.log('ERR!', err);
    //     })
    // }, []); 

  
    
    useEffect(
		() => {
            filterGallery(album);
		},
		[album]
	);




    // const dropdownItem = albums.map((album) => {
    //     return(
    //         <Dropdown.Item  key={album.id} onClick={() => {
    //             setAlbum(album.id - 1)
    //             filterGallery(album.id)
    //         }}>{album.title}</Dropdown.Item>
    //     )
    // });

    
	return (
    <>
        <Parallax bgImage={beach} blur={{ min: -4, max: 4 }} strength={-150}>
            <header className='gallery-header'>
                    <div className='gallery-header-content'>
                        <h1>CODAH<br></br> GALLERY</h1>
                    </div>  
            </header>
        </Parallax>
        
        <div className='gallery-container'>
            <h2>Album Select</h2>
            <p>Please click to select an album</p>
            <Dropdown className='dropdown' >
                {/* <Dropdown.Toggle split variant="success" id="dropdown-split-basic">{album === undefined ? 'Please Select an Album ' : albums[album].title + " "}</Dropdown.Toggle> */}
                <Dropdown.Toggle split variant="success" id="dropdown-split-basic">{album === 'default' ? 'Please Select an Album ' : album}</Dropdown.Toggle>
                <Dropdown.Menu>
{/*                
                  {dropdownItem} */}
              
                   <Dropdown.Item onClick={() => {
                    setAlbum('Independence Celebration 2019 ');
                   }}>Independence Celebration 2019 
                   </Dropdown.Item>
                   <Dropdown.Item onClick={() => {
                    setAlbum('Market Place 2018 ');
                   }}>Market Place 2018 
                   </Dropdown.Item>
                   <Dropdown.Item onClick={() => {
                    setAlbum('Christmas 2018 ');
                   }}>Christmas 2018 
                   </Dropdown.Item>
                   <Dropdown.Item onClick={() => {
                    setAlbum('Tourism ');
                   }}>Tourism
                   </Dropdown.Item>
                   <Dropdown.Item onClick={() => {
                    setAlbum('Hurricane Relief 2017 ');
                   }}>Hurricane Relief 2017
                   </Dropdown.Item>
                   <Dropdown.Item onClick={() => {
                    setAlbum('Canada Day 2017 ');
                   }}>Canada Day 2017
                   </Dropdown.Item>
                   <Dropdown.Item onClick={() => {
                    setAlbum('Natural Disasters ');
                   }}>Natural Disasters
                   </Dropdown.Item>
                   <Dropdown.Item onClick={() => {
                    setAlbum('Belle Marche/Confederation Park picnic ');
                   }}>Belle Marche/Confederation Park picnic
                   </Dropdown.Item>
                   <Dropdown.Item onClick={() => {
                    setAlbum('Independence Dance November 2013 ');
                   }}>Independence Dance November 2013
                   </Dropdown.Item>
                   <Dropdown.Item onClick={() => {
                    setAlbum('Mothers Day 2014 ');
                   }}>Mothers Day 2014
                   </Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                    setAlbum('Miscellaneous ');
                   }}>Miscellaneous
                   </Dropdown.Item>
                </Dropdown.Menu>
                
            </Dropdown>
            <div className='gallery'>
                <Gallery images={album === 'default' ? IMAGES : gallery} />
                <h3>Have any interesting photos that you would like to share with us?<br></br> Please send them to codahamilton@gmail.com!</h3>
            </div>
        </div>
    </>
  );
}
