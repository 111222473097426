import './css/index.css'
import './img/classroom.webp'

import { useLocation, useNavigate } from "react-router-dom";

export default function Footer() {
    const location = useLocation();

    let navigate = useNavigate();
	return (
        <div className={location.pathname === '/donate' ? 'reach-our-goals-hide' : 'reach-our-goals'}>
                <div className='reach-our-goals-overlay'>
                    <div className='reach-our-goals-content'>
                        <h1>Help us reach our goals</h1>
                        <h4>We can’t help everyone, but everyone can help someone.</h4>
                        <h5>CODAH is a Non-profit organization that supports students, the elderly and communities impacted by a natural disaster</h5>
                        <button onClick={() => navigate('/donate')}>DONATE NOW</button>
                    </div> 
                </div>
        </div>
  );
}
