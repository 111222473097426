import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { AiOutlineCloseSquare } from 'react-icons/ai';



import { Link } from 'react-router-dom';
import './public/css/index.css';

import  logo  from '../../componenets/Header/img/codah-logo.webp'

export default function MobileHeader() {
	const [ navbar, setNavbar ] = useState(false);
	const [ menu, setMenu ] = useState(false);

  // changes nav colours after the viewport reaches a certain point on the y axis
	const changeBackground = () => {
		if (window.scrollY >= 80) {
			setNavbar(true);
		} else {
			setNavbar(false);
		}
	};

  // on openMenu show lightbox
	const openMenu = () => {
		if (!menu) {
			setMenu(true);
		}
	};

  // on openMenu close lightbox
  // The light box and mobile header will be hidden if the viewport width becomes wider than 769px
	const closeMenu = () => {
		if (menu) {
			setMenu(false);
		}
	};

	window.addEventListener('scroll', changeBackground);

	return (
    <nav className={"mobile-nav-bar"}>
      <div className={'mobile-nav-logo'}>
            <img src={logo}></img>
      </div>

      <Link to="#">
        <FaBars
          className={
            "hamburgerMenuStyled"
          }
          onClick={openMenu}
        />
      </Link>

      <div className={menu ? "light-box" : "light-box-hide"}>
        <div className='menu-header'> 
          <AiOutlineCloseSquare
          className={menu ? "closeBtnStyled" : "closeBtnHide"}
          onClick={closeMenu}
          />
          <span>MENU</span>
        </div>
       

        <div className={menu ? "light-box-menu" : ""}>
          <ul>
            <li>
              <a href="/">
                <span>Home</span>
              </a>
            </li>
            <li>
              <a href="history">
                <span>History</span>
              </a>
            </li>
            <li>
              <a href="events">
                <span>Events</span>
              </a>
            </li>
            <li>
              <a href="gallery">
                <span>Gallery</span>
              </a>
            </li>
            <li>
              <a href="donate">
                <span>Donate</span>
              </a>
            </li>
            <li>
              <a href="contact">
                <span>Contact</span>
              </a>
            </li>
            <li> 
              <Link to="/donate">
              <a className='donate-button'>DONATE</a>
              </Link>
            
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
