import './css/index.css'
import Cause from '../Cause'

import { AiOutlineCloseSquare } from 'react-icons/ai';

import { Parallax } from 'react-parallax';

import island from './img/island.webp'

import greenShirt from './img/greenShirt.webp'
import { useNavigate } from 'react-router-dom';

import ProgressBar from "@ramonak/react-progress-bar";

import kids from './img/kids.webp'
import supplies from './img/supplies.webp'
import members from './img/members.webp'





export default function Home() {
    let navigate = useNavigate()
  
	return (
        <div className='home'>
           
            <Parallax bgImage={greenShirt} blur={{ min: -8, max: 8 }} strength={-150}>
                <header className='home-header'>
                    <div className='space'></div>
                        <div className='home-header-content'>
                            <h1>The Commonwealth of Dominica Association, Hamilton</h1>
                        </div>
                </header>

            </Parallax>
       
            <Parallax bgImage={island} >
                <div className='who-we-are-overlay'>
                    <div className='who-we-are-content'>
                        <h1>Who We Are</h1>
                        <p>The Commonwealth of Dominica Association, Hamilton (CODAH) was formed 40+ years ago to help our community. Over the years we have concentrated on doing just that, whilst saving some time to come together and enjoy ourselves at various functions and events.</p>
                        <button onClick={() => navigate('/history')}>Read More</button>
                    </div>
                </div>
            </Parallax>
            
                <h3>You can help lots by donating little. See our causes.</h3>
            <div className='cause-container'>
                    {/* creates causes dynamically */}
                    {/* <Cause /> */}
                <div className='cause'>
                    <img src={kids}></img>
                    <div className='cause-content'>
                        <h2>{"The youth of Dominica need your support for school supplies"}</h2>
                        {/* <h4>Donations: {`$2425`}/{`$5000`}</h4>
                        <ProgressBar className='progress-bar' bgColor='yellow' height='0.6vh' labelColor='yellow' completed={((2425 / 5000) * 100)} /> */}
                        <button onClick={() => navigate('/donate')}>DONATE NOW</button>
                    </div>
                </div>
                <div className='cause'>
                    <img src={members}></img>
                    <div className='cause-content'>
                        <h2>{"The people of Dominica need help building public infrastructure."}</h2>
                        {/* <h4>Donations: {`$8527`}/{`$15000`}</h4>
                        <ProgressBar className='progress-bar' bgColor='yellow' height='0.6vh' labelColor='yellow' completed={((8527 / 15000) * 100)} /> */}
                        <button onClick={() => navigate('/donate')}>DONATE NOW</button>
                    </div>
                </div>
            </div>
                  
        </div>
       
  );

}