import { useState } from 'react';
import './css/index.css'

import volunteers  from '../Donate/img/volunteers.webp'

import PersonalInformation from './personalInformation';
import BasicInformation from './basicInformation';
import Payment from './payment';
import CauseSelect from './causeSelect';

import { Parallax } from 'react-parallax';

export default function Donate() {

    const [page, setPage] = useState(0);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        donationAmount: "",
        city: "",
        country: "",
        province: "",
        address: "",
        postalCode: "",
        cause:""
    });


    const PageDisplay = () => {
        if (page === 0) {
          return <BasicInformation formData={formData} setFormData={setFormData} />;
        } else if (page === 1) {
            return <PersonalInformation formData={formData} setFormData={setFormData} />;
        } else if (page === 2) {
            return <CauseSelect formData={formData} setFormData={setFormData} />;
        } else {
          return <Payment formData={formData} setFormData={setFormData} />;
        }
      };
    
	return (
    <>
    <Parallax bgImage={volunteers} blur={{ min: -4, max: 4 }} strength={-150}>
        <header className='donate-header'>
                <div className='donate-header-content'>
                    <h1>MAKE A<br></br>DONATION</h1>
                </div>  
        </header>
    </Parallax>

      <div className='donate-container'>
          <div className='filler'></div>
          <div className='donate-form-section-formatting'>
              {/* <div className='donate-form-section'>
                   <form>
                    <header>
                        <h1>Create Change Today!</h1>
                        <p>Every donation no matter how big or small, makes a significant difference to our cause. Thank you for doing your part to help.</p>
                    </header>
                    
                    <div className="form-body">{PageDisplay()}</div>

                    <footer>
                        <button type='button' disabled={page === 0} onClick={() => {
                            setPage((currPage) => currPage - 1);
                        }}>Prev</button>
                                    <button type='button' disabled={page === 3} onClick={() => {
                            setPage((currPage) => currPage + 1);
                        }}>Next</button>
                    </footer>
                  </form>
                  
              </div> */}
              <p>At this current moment CODAH is not registered as a non-profit organization. However, if you'd like to contribute to our causes please contact us at codahamilton@gmail.com!</p>
          </div>
          
    </div>
    
    </>
  );
}
